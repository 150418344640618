@use './variables.scss' as v;

div#ssologin-container.daysmart-booking-style-class {
    .row {
        margin-bottom: 16px;

        label {
            font-size: 14px;
            margin-bottom: 6px;
            display: flex;
            align-items: center;
            height: 14px;
            color: #707070;

            &.error-color {
                color: v.$error-color;
            }

            .material-icons {
                font-size: 16px;
                margin-left: 0;
            }
        }

        .mat-mdc-form-field {
            .mat-mdc-text-field-wrapper {
                padding: 0;

                .mat-mdc-form-field-flex {
                    display: flex;
                    padding: 0;
                    border: 1px solid #bdbdbd;
                    border-radius: 4px;
                    height: 40px;
                    align-items: center;
                    background-color: #fff;

                    &:focus-within {
                        border: 0.15rem solid v.$dynamic-theme-color;
                        border-radius: 4px;
                    }

                    .mdc-notched-outline {
                        .mdc-notched-outline__leading,
                        .mdc-notched-outline__notch,
                        .mdc-notched-outline__trailing {
                            border: unset;
                        }
                    }

                    .mat-mdc-form-field-infix {
                        display: flex;
                        align-items: center;
                        padding: 10px;

                        .mdc-text-field .mdc-text-field__input {
                            caret-color: v.$dynamic-theme-color;
                        }

                        input {
                            font-size: 16px;
                            height: 20px;
                        }
                    }
                }
            }

            .mat-mdc-form-field-subscript-wrapper {
                display: none;
            }

            &.error-color > .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
                border-color: v.$error-color;
            }
        }
    }

    .row.buttons {
        display: flex;
        width: 100%;
        justify-content: unset;
        flex-direction: unset;

        .mdc-button.mdc-button--raised.mat-mdc-raised-button {
            width: 100%;
            margin-left: unset;
            background-color: v.$dynamic-theme-color;
        }
    }
}
